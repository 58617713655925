import { Content } from '@/components';
import { Layout } from '@/layout';
import React from 'react';
import Location from '@/components/map/Location';

export default function about() {
  return (
    <Layout>
      <Content>
        <div className="w-8/10 flex-col lg:flex-row flex mt-10">
          <div className="lg:w-1/2 bg-background-600 p-6 lg:p-20 rounded lg:rounded-l-lg">
            <div className="text-3xl font-semibold italic text-third">
              Informatie
            </div>
            <div className="text-lg text-primary-500 font-semibold mt-4">
              Heeft u vragen en/of opmerkingen? Stuur ons een bericht en we
              zullen zo snel mogelijk reageren.
            </div>
            <div className="mt-5">
              <div className="mb-3">
                Wilt u algemene informatie over het toernooi of event op vrijdag
                8 en zaterdag 9 juli?
                <br />
                Neem contact op met:
              </div>
              <div className="text-base font-semibold text-third">SVSibbe</div>
              <div className="text-sm text-gray-500">
                Sportvereniging Sibbe - Kleine Linde 4 6301AN Sibbe
                <br />
                Email: Beachevent@svsibbe.nl
                <br />
                KvK: 40203788
                <br />
                Website: <a href="https://www.svsibbe.nl">www.svsibbe.nl</a>
              </div>
              <div className="mt-4">
                <div className="mb-3">
                  Wilt u meer informatie over het toernooi op zondag 10 juli?
                  <br />
                  Neem contact op met:
                </div>
                <div className="text-base font-semibold text-third">
                  VV S.E.C.
                </div>
                <div className="text-sm text-gray-500">
                  VC SEC - Plenkertstraat 50 6301GM Valkenburg a/d Geul
                  <br />
                  Email: info@vcsec.nl
                  <br />
                  Website: <a href="https://www.vcsec.nl/">www.vcsec.nl</a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 ml-0 bg-background-600 rounded p-6 lg:p-20 lg:mt-0 lg:border-l-2 lg:ml-1 lg:w-1/2 lg:rounded-r-lg">
            <div className="text-3xl font-semibold italic text-third mb-4">
              Locatie
            </div>
            <Location />
          </div>
        </div>
      </Content>
    </Layout>
  );
}
